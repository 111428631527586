import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NurseRating from './components/NurseRating';
import DoctorRating from './components/DoctorRating';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from './components/Alert';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL, APP_NAME } from './Constants';

axios.defaults.baseURL = API_URL;

function RateHospitalStaff() {
  const location = useLocation();
  const value = location.state?.value || localStorage.getItem('IPEncounter'); 
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [overallVisitNurseRating, setOverallVisitNurseRating] = useState(0);
  const [overallVisitDoctorRating, setOverallVisitDoctorRating] = useState(0);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [submitted, setSubmitted] = useState(false); 
  const [arr, setArr] = useState([]);
  const [arr1, setArr1] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        debugger;

        const response = await axios.get('/GetNurseandDoctorRatingDetails', {
          params: { Encounter: value }, // Adds 'Encounter=value' as a query parameter
          headers: { 'Content-Type': 'application/json' }
        });
        

        if (response.data.success) {
          const parsedData = JSON.parse(response.data.data);
          setLoginData(Array.isArray(parsedData) ? parsedData : [parsedData]);

          const nurseData = parsedData.filter(user => user.RoleName === 'Nurse');
          const doctorData = parsedData.filter(user => user.RoleName === 'Doctor');

          if (nurseData.length > 0) {
            setOverallVisitNurseRating(Math.round(nurseData[0].OverallVisitNurseRating || 0));
          }

          if (doctorData.length > 0) {
            setOverallVisitDoctorRating(Math.round(doctorData[0].OverallVisitDoctorRating || 0));
          }
        } else {
          setError(response.data.errorMessage || 'Login failed, no data available.');
        }
      } catch (err) {
        console.error('API error:', err.response ? err.response.data : err.message);
        setError('An error occurred while fetching the data.');
      } finally {
        setLoading(false);
      }
    })();
  }, [value]);


  const setArrFunc = (nurseRatingData) => {
    setArr(prevArr => ({
      ...prevArr,
      [nurseRatingData.Visitng_Pk_Id]: nurseRatingData
    }));
  };

  const setArrFunc1 = (doctorRatingData) => {
    setArr(prevArr => ({
      ...prevArr,
      [doctorRatingData.Visitng_Pk_Id]: doctorRatingData
    }));
  };

  const SaveNurseandDoctorRatingDetails = async () => {

    const overallNurseRating = arr.reduce((acc, item) => acc + item.Rating, 0) / arr.length;
    const overallDoctorRating = arr1.reduce((acc, item) => acc + item.Rating, 0) / arr1.length;

    setOverallVisitNurseRating(overallNurseRating);
    setOverallVisitDoctorRating(overallDoctorRating);

    const CombinedRatings = [
      ...arr.map(item => ({
        Visitng_Pk_Id: item.Visitng_Pk_Id || '',
        Rating: item.Rating,
        Comment: item.Comment || '',
        VisitBy: item.VisitBy || '', 
        UserID: item.UserID || '', 
        Role_Id: item.Role_Id || '',
        Userdept: item.Userdept || '',
        Notifyon: item.Notifyon || '',
        IsNotify: item.IsNotify || '',
        RMail_list: item.RMail_list || '',
        Category: 'Nurse'
      })),
      ...arr1.map(item => ({
        Visitng_Pk_Id: item.Visitng_Pk_Id || '',
        Rating: item.Rating,
        Comment: item.Comment || '',
        VisitBy: item.VisitBy || '', 
        UserID: item.UserID || '', 
        Role_Id: item.Role_Id || '',
        Userdept: item.Userdept || '',
        Notifyon: item.Notifyon || '',
        IsNotify: item.IsNotify || '',
        RMail_list: item.RMail_list || '',
        Category: 'Doctor'
      }))
    ];
    const requestData = CombinedRatings.map(item => ({
      Encounter: value || '', 
      OverallVisitNurseRating: overallNurseRating,
      OverallVisitDoctorRating: overallDoctorRating,
      Visitng_Pk_Id: item.Visitng_Pk_Id || '',
      RatingsVar: item.Rating,
      Comment: item.Comment || '', 
      VisitBy: item.VisitBy || '', 
      PatientName: nurseData[0].PatientName || '', 
      Patient_Pk_Id: nurseData[0].Patient_Pk_Id || '', 
      UserID: item.UserID || '', 
      EmailID: nurseData[0].EmailID || '', 
      Role_Id: item.Role_Id || '',
      Userdept: item.Userdept || '',
      Notifyon: item.Notifyon || 0,
      IsNotify: item.IsNotify || false,
      RMail_list: item.RMail_list || '',
    }));

    console.log('Request Data:', requestData); 

    try {
      const response = await axios.post(
        'SaveNurseandDoctorRatingDetails',
        requestData, 
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.data.success) {
        setAlert({ message: 'Data saved successfully...!', type: 'success' });
      } else {
        setAlert({ message: 'Something went wrong...!', type: 'error' });
      }
    } catch (error) {
      console.error('Error saving data:', error.response ? error.response.data : error.message);
      if (error.response?.data?.errors) {
        console.error('Validation Errors:', error.response.data.errors);
      }
    }
};

  const handleAlertClose = () => {
    setAlert({ message: '', type: '' });
  };

  const renderStars = (rating, onClick, disabled = false) => {
    return Array.from({ length: 5 }, (_, i) => (
      <i
        key={i + 1}
        className={`bi ${i + 1 <= rating ? 'bi-star-fill text-warning' : 'bi-star text-secondary'} mx-1`}
        onClick={() => !disabled && onClick(i + 1)}
        style={{
          cursor: disabled ? 'default' : 'pointer',
          fontSize: '1.2em'
        }}
      ></i>
    ));
  };

  const handleOverallNurseRatingClick = (rating) => {
    setOverallVisitNurseRating(rating);
  };

  const handleOverallDoctorRatingClick = (rating) => {
    setOverallVisitDoctorRating(rating);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const nurseData = loginData.filter(user => user.RoleName === 'Nurse');
  const doctorData = loginData.filter(user => user.RoleName === 'Doctor');

  const handleSubmit = () => {
    setSubmitted(true); 
  };

  const handleBack = () => {
    setTimeout(() => {
      navigate('/PatientExperience');  // Navigate back after alert
    }, 1000);
  };

  if (submitted) {
    return (
      <>
        <div className="mt-5 message">
          <div className="thank-you-message bordersubmit pt-4 pb-4">
            <h2 className="text- successsubmit text-center">Successfully</h2>
            <h2 className="text- successsubmit text-center">Submitted your feedback response</h2>
            <h4 className="text-center mt-4">Thank you for your time and patience</h4>
            <h1 className="text-center mt-3">Have a good Day!!!</h1>
          </div>
  
          <div className="text-center mt-4">
            <h4 className="h5">
              Would you like to rate the 
              <Link to="/PatientExperience/Ratehospitalservice" className="text- spannavy "> Rate Hospital Service</Link>
            </h4>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="bodyHeader">Rate Hospital Staff</div>

      {alert.message && (
        <Alert message={alert.message} type={alert.type} onClose={handleAlertClose} />
      )}

      {/* Nurse Rating Card */}
      <div className="card mt-1">
      <div className='mt-3 ms-4 me-4'>
        <div className=" text-success"><strong>Nurse Rating</strong></div>
        <div className="">
          <div className="row g-2" style={{minWidth:'150px'}}>
            {nurseData.map((nurse) => (
                <div key={nurse.Visitng_Pk_Id} className="col-sm-4 col-xs-12 mb-0"> 
                <NurseRating nurseData={[nurse]} setArrFunc={setArr}/>
              </div>
            ))}
          </div>
          </div>
          <hr className='mt-0' />  
         <div className="col-12 d-flex flex-md-row flex-column p-0 my-3">
            <div className="secondarylinkcolor overall me-md-2 mb-2 mb-md-0">Overall Visit Nurse Rating</div>
            <div>{renderStars(overallVisitNurseRating, handleOverallNurseRatingClick, true)}</div>
          </div>
        </div>
      </div>

      {/* Doctor Rating Card */}
      <div className="card mt-4">
        <div className='mt-3 ms-4 me-4'>
        <div className=" text-success"><strong>Doctor Rating</strong></div>
        <div className="">
          <div className="row g-3">
            {doctorData.map((doctor) => (
                  <div key={doctor.Visitng_Pk_Id} className="col-sm-4 col-xs-12 mb-0">  
                <DoctorRating doctorData={[doctor]} setArrFunc1={setArr1}/>
              </div>
            ))}
          </div>
          <hr className='mt-0' /> 
          </div>
          <div className="col-12 d-flex flex-md-row flex-column p-0 my-3">
            <div className="secondarylinkcolor overall me-md-1 mb-1 mb-md-0">Overall Visit Doctor Rating</div>
            <div>{renderStars(overallVisitDoctorRating, handleOverallDoctorRatingClick, true)}</div>
          </div>
        </div>
      </div>
       <div className="d-flex justify-content-between mt-4 btnbottom"> 
        <button className="btn btn-secondary w-25 ms-4 stepbtn me-2" onClick={handleBack}>
          Back
        </button>
        <button className="btn btn-secondary btnsubmit w-25 stepbtn me-2" onClick={() => { SaveNurseandDoctorRatingDetails(); handleBack() }}>
          Save
        </button>
        <button className="btn btn-primary btnsubmit w-25 me-4 stepbtn" onClick={() => { SaveNurseandDoctorRatingDetails(); handleSubmit(); }}>
          Submit
        </button>
      </div>

    </>
  );
}

export default RateHospitalStaff;
